import React from "react";

const Settings = () => {
  return (
    <div className="p-5">
      <h1 className="font-bold text-2xl">Settings</h1>
      <h1 className="font-semibold">Coming Soon</h1>
    </div>
  );
};

export default Settings;
