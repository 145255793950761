import React from "react";
import Logo from "../assets/logoGrey.png";

const ExIphoneLogo = () => {
  return (
    <>
      {/* <img src={Logo} alt="Helah. logo" /> */}
      <p>Helah.</p>
    </>
  );
};

export default ExIphoneLogo;
