import React from "react";

const Help = () => {
  return (
    <div className="p-5">
      <h1 className="font-bold text-2xl">Help</h1>
      <p>Please whatsapp us at +91 8921992747</p>
    </div>
  );
};

export default Help;
